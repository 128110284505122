import { Fragment, useEffect, useState, Suspense } from "react";
import { UserAuth } from "../context/AuthContext";
import { db } from "../firebase";
import { format } from "date-fns";
import ResponseModal from "../components/modal/ResponseModal";
import { getResponses, featureCheck } from "../services/api";
import Stats from "../components/journal/Stats";
import * as Constants from "util/constants";
import Loading from "components/common/Loading";

import { Disclosure, Menu, Transition } from "@headlessui/react";
import { getUserMetrics } from "../services/api";

// TODO: Remove this

function generateSample() {
  return {
    id: "id-1",
    audiopath: null,
    audioUrl: null,
    content: "test content",
    filePaths: [],
    fileURLs: [],
    mood: null,
    pid: "abc",
    prompt: {
      assigned_date: new Date(),
      id: "abc",
      prompt: "What brings you to SaySo?",
    },
  };
}

export default function LibraryView() {
  const [library, setLibrary] = useState([]);
  const [open, setOpen] = useState(false);
  const [viewable, setViewable] = useState(null);
  const [metrics, setMetrics] = useState(null);
  const [isPremium, setIsPremium] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const { user } = UserAuth();

  const getFormattedDate = (date) => {
    try {
      return format(date.toDate(), "MMMM do, yyyy H:mm a");
    } catch (e) {
      console.log("Failed to format date");
    }
  };

  const viewResponse = (e, response) => {
    e.preventDefault();
    setViewable(response);
    setOpen(true);
  };

  const getLibrary = async (uid) => {
    const docs = await getResponses(user);
    return docs;
  };

  const getFormattedPreview = (content) => {
    const maxLen = 40;
    if (content.length > maxLen) {
      return content.substring(0, maxLen) + "...";
    }
    return content;
  };
  // DISABLED TODO: Reenable
  useEffect(() => {
    if (user) {
      getLibrary(user.uid).then((library) => {
        setLibrary(library);
      });

      featureCheck(user, Constants.FEATURE_VIEW_METRICS).then((result) => {
        if (result) {
          getUserMetrics(user.uid).then((metrics) => {
            setMetrics(metrics);
            setIsLoading(false);
          });
        } else {
          setIsPremium(false);
          setIsLoading(false);
        }
      });
    }
  }, [user]);

  return (
    <>
      <ResponseModal open={open} setOpen={setOpen} viewable={viewable} />
      <div className="px-4 sm:px-6 lg:px-8 mt-5 lg:mt-20">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-2xl font-semibold leading-6 text-gray-900">
              Journal Insights
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Here are some insights around your journals. Click on the icons to
              learn more about them.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none"></div>
        </div>

        <Stats metrics={metrics} locked={!isPremium} isLoading={isLoading} />

        <div className="my-6">
          <hr />
        </div>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-2xl font-semibold leading-6 text-gray-900">
              Library
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all your journal responses - this library is all for
              you.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none"></div>
        </div>
        <div className="mt-4 sm:mt-8">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="bg-white overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg m-4 sm:m-0 ">
                {library.length <= 0 && (
                  <p className="m-5 animate-fade">
                    You have not submitted any responses yet!
                  </p>
                )}
                {library.length > 0 && (
                  <table className="min-w-full divide-y divide-gray-300 animate-fade">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          className="hidden md:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Prompt
                        </th>
                        <th
                          scope="col"
                          className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Mood
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white ">
                      {library.map((response) => (
                        <tr key={response.id} className="even:bg-gray-50">
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {getFormattedDate(response.timestamp)}
                          </td>
                          <td className="hidden md:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {getFormattedPreview(response.prompt?.prompt)}
                          </td>
                          <td className="hidden sm:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {response.mood}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <a
                              onClick={(e) => viewResponse(e, response)}
                              className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                            >
                              View<span className="sr-only"></span>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
