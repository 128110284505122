import { PropaneSharp } from "@mui/icons-material";
import GoogleIcon from "@mui/icons-material/Google";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { UserAuth } from "../context/AuthContext";
import Loading from "../components/common/Loading";
import getErrorMessage from "../util/error-codes";
import Alert from "../components/alert/Alert";
import ReCaptchaDisclosure from "../components/common/ReCAPTCHADisclosure";
import SaySoDisclosure from "../components/common/SaySoDisclosure";

const emailFailAlert = (message) => {
  return {
    type: "fail",
    color: "red",
    title: message,
  };
};

export default function SignInView() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    signIn,
    signInWithGoogle,
    user,
    handleRedirect,
    sendUserPasswordResetEmail,
  } = UserAuth();

  useEffect(() => {
    if (user != null) {
      navigate("/u/home");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    await signIn(email, password)
      .then((result) => {
        navigate("/u/home");
      })
      .catch((error) => {
        setError(emailFailAlert(getErrorMessage(error)));
      });
    setLoading(false);
  };

  const handleProviderLogin = async () => {
    setLoading(true);
    try {
      await signInWithGoogle();
    } catch (e) {
      setError(e.message);
    }
    setLoading(false);
  };

  const forgotPassword = () => {
    navigate("/forgot-password");
  };

  return (
    <>
      {loading && (
        <div className="h-screen flex items-center justify-center">
          <Loading />
        </div>
      )}
      {!loading && (
        <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-slate-50">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="mx-auto h-10 w-auto"
              src="sayso-logo.png"
              alt="SaySo"
            />
            <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Sign in to your account
            </h2>
          </div>

          <div className="lg:mt-10 mt-5 mx-2 sm:mx-auto sm:w-full sm:max-w-[480px]">
            {error && <Alert alert={error} />}
            <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12 ">
              <form className="space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Password
                  </label>
                  <div className="mt-2">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                    <label
                      htmlFor="remember-me"
                      className="ml-3 block text-sm leading-6 text-gray-900"
                    >
                      Remember me
                    </label>
                  </div>

                  <div className="text-sm leading-6">
                    <a
                      onClick={forgotPassword}
                      className="font-semibold text-indigo-600 hover:text-indigo-500 hover:underline cursor-pointer"
                    >
                      Forgot password?
                    </a>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Sign in
                  </button>
                </div>
              </form>

              <div>
                <div className="relative mt-10">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-200" />
                  </div>
                  <div className="relative flex justify-center text-sm font-medium leading-6">
                    <span className="bg-white px-6 text-gray-900">
                      Or continue with
                    </span>
                  </div>
                </div>

                <div className="relative mt-10">
                  <button
                    onClick={handleProviderLogin}
                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    <GoogleIcon />
                    <span className="text-sm font-semibold leading-6 mx-2">
                      Google
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <p className="mt-10 text-center text-sm text-gray-500">
              Not a member?{" "}
              <a
                href="/sign-up"
                className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
              >
                Sign up for an account
              </a>
            </p>
            <ReCaptchaDisclosure />
          </div>
        </div>
      )}
    </>
  );
}
