import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { UserAuth } from "../context/AuthContext";
import Alert from "../components/alert/Alert";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Loading from "../components/common/Loading";

const emailSuccessAlert = {
  type: "success",
  color: "green",
  title: "Please check your emails to complete setup!",
  // messages: ['Please check your emails for a verification link to complete account setup!']
};

const emailFailAlert = {
  type: "fail",
  color: "red",
  title:
    "An unexpected error occurred when sending your verification email. Please contact us at help@sayso-project.com",
};

export default function VerifyEmail() {
  const [open, setOpen] = useState(true);
  const [viewable, setViewable] = useState({});
  const [loading, setLoading] = useState(false);

  const { sendUserEmailVerification, user, logout } = UserAuth();

  const navigate = useNavigate();

  const handleResendEmail = () => {
    setViewable({});
    setLoading(true);

    sendUserEmailVerification()
      .then((resp) => {
        setViewable(emailSuccessAlert);
      })
      .catch((error) => {
        setViewable(emailFailAlert);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleLogout = () => {
    logout();
    // navigate('/login')
  };

  useEffect(() => {
    if (user?.emailVerified) {
      navigate("/u/home");
    }
  }, [user]);

  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <EnvelopeIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3  sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base text-center font-semibold leading-6 text-gray-900"
                    >
                      Verify your email
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 ">
                        We recently sent you an email (<b>{user.email}</b>) with
                        a link to verify your email address. Please follow the
                        link in the email to complete your account setup.
                      </p>
                    </div>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 ">
                        Completed your verification?{" "}
                        <a
                          onClick={(e) => navigate(0)}
                          className="text-blue-500 underline cursor-pointer"
                        >
                          Click here to refresh this page
                        </a>
                        .
                      </p>
                    </div>
                    {Object.keys(viewable).length > 0 && (
                      <div className="my-4">
                        <Alert alert={viewable} />
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className={`${
                      loading ? "bg-indigo-500" : "bg-indigo-600"
                    } inline-flex w-full justify-center rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                    onClick={() => handleResendEmail()}
                    disabled={loading}
                  >
                    {loading && <Loading size="h-5 w-5" />}
                    {!loading ? "Resend email" : ""}
                  </button>
                </div>
                <div className="mt-5 sm:mt-4 flex items-center justify-center">
                  <button
                    type="button"
                    className={`${
                      loading ? "bg-gray-100" : "bg-white hover:bg-gray-50"
                    } mt-3 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:mt-0`}
                    onClick={() => handleLogout()}
                    ref={cancelButtonRef}
                    disabled={loading}
                  >
                    Logout
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
