export const FEATURE_RECORD = "audio";
export const FEATURE_FILE_UPLOAD = "file";
export const FEATURE_GENERATE_PROMPT = "generate_prompt";
export const FEATURE_VIEW_METRICS = "view_metrics";

export const PRODUCT_BASIC = "Paperback Subscription";
export const PRODUCT_PREMIUM = "Hardcover Subscription";
export const PRODUCT_GROUP = "Library (Enterprise)";

export const ROUTES = {
  USER_WRITE: "/u/home",
  USER_UPGRADE: "/u/billing",
};

export const PRODUCT_BASIC_FEATURES = [
  "Voice journaling",
  "Change prompt frequency",
  "Unlimited journaling",
];

export const PRODUCT_PREMIUM_FEATURES = [
  "Everything from paperback",
  "Prompts on demand",
  "Sentiment analysis",
  "Access to SaySo 25",
];

export const PRODUCT_GROUP_FEATURES = [
  "Everything from hardcover",
  "Advanced monthly metrics",
  "Reports on demand",
  "Dedicated support",
];

export const SECURITY_LEVEL_1 = "level_1";
export const SECURITY_LEVEL_2 = "level_2";
export const SECURITY_LEVEL_3 = "level_3";

export const SAYSO_EMAIL = "admin@sayso-project.com";

export const SECURITY_FEATURE_MAP = {
  [FEATURE_RECORD]: [SECURITY_LEVEL_1, SECURITY_LEVEL_2],
  [FEATURE_FILE_UPLOAD]: [SECURITY_LEVEL_1, SECURITY_LEVEL_2],
  [FEATURE_GENERATE_PROMPT]: [SECURITY_LEVEL_2],
  [FEATURE_VIEW_METRICS]: [SECURITY_LEVEL_2],
};

export const ERROR_GENERIC = "Something went wrong!";

export const SUBSCRIPTION_FAQ = [
  {
    question: "Can I get a free trial?",
    answer: `We offer a generous free trial for new users! Get in touch at ${SAYSO_EMAIL}`,
  },
  {
    question: "How do I subscribe?",
    answer:
      "You'll first need to make an account with us, and then go to the subscription page.",
  },
  {
    question: "I'm not journalling, can I get a refund?",
    answer: `No worries! Get in contact with us at ${SAYSO_EMAIL}, and we will sort something out with you.`,
  },
  {
    question: "Something went wrong, what do I do?",
    answer: `Get in touch with us at ${SAYSO_EMAIL}. We are happy to help!`,
  },
];

export const METRIC_SENTIMENT_SCORE_MAP = {
  "Feeling Heavy": { lower: -1, upper: -0.8 },
  Gloomy: { lower: -0.8, upper: -0.5 },
  Melancholy: { lower: -0.5, upper: -0.2 },
  "On a Positive Note": { lower: -0.2, upper: 0.2 },
  Rosy: { lower: 0.2, upper: 0.5 },
  Positive: { lower: 0.5, upper: 0.8 },
  Radiant: { lower: 0.8, upper: 1 },
};

export const METRIC_SENTIMENT_MAGNITUDE_MAP = {
  Reserved: { lower: 0, upper: 2 },
  Balanced: { lower: 2, upper: 5 },
  Expressive: { lower: 5, upper: 99999999999 },
};

export const BANNER_DEV = {
  title: "DEVELOPMENT",
  message: "THIS IS A DEVELOPMENT ENVIRONMENT",
  color: "bg-red-600",
};

export const BANNER_ALPHA_LAUNCH = {
  title: "SaySo Alpha Launch",
  message: "Welcome to the Alpha launch of SaySo!",
  color: "bg-blue-600",
};
