import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckIcon,
  QuestionMarkCircleIcon,
  StarIcon,
  TrophyIcon,
} from "@heroicons/react/24/outline";
import { StarIcon as SolidStarIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { UserAuth } from "../../context/AuthContext";
import { setGeneratePrompt } from "../../services/api";

export default function Modal(props) {
  //   const [open, setOpen] = useState(true)
  var open = props.open;
  var setOpen = props.setOpen;

  const [confirm, setConfirm] = useState(false);

  const { user } = UserAuth();

  const navigate = useNavigate();

  const generateNewPrompt = async () => {
    // UPLOAD RESPONSE DOC
    const resp = await setGeneratePrompt(user);

    if (resp.success) {
      console.log("New document written successfully");
      setConfirm(true);
      setOpen(false);
    } else {
      console.log("Failed to write document");
      console.log(resp.error.title);
    }
  };

  const cancelButtonRef = useRef(null);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={(e) => setOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                      <QuestionMarkCircleIcon
                        className="h-6 w-6 text-gray-600 animate-ease-in"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        New prompt
                      </Dialog.Title>
                      <div className="my-4">
                        <p className="text-sm text-gray-500">
                          Do you want to generate a new prompt?
                        </p>
                      </div>
                    </div>
                    <div className="my-4">
                      <hr />
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className={`bg-indigo-600 inline-flex w-full justify-center rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                        onClick={generateNewPrompt}
                      >
                        Yes, give me a new prompt!
                      </button>
                    </div>
                    <div className="mt-5 sm:mt-4 flex items-center justify-center">
                      <button
                        type="button"
                        className={`bg-white hover:bg-gray-50 mt-3 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:mt-0`}
                        ref={cancelButtonRef}
                        onClick={(e) => setOpen(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={confirm} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={(e) => setConfirm(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <ArrowPathIcon
                        className="h-6 w-6 text-green-600 animate-ease-in"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Request Submitted
                      </Dialog.Title>
                      <div className="my-4">
                        <p className="text-sm text-gray-500">
                          Your new prompt will be with you shortly!
                        </p>
                      </div>
                    </div>
                    <div className="my-4">
                      <hr />
                    </div>
                    <div className="mt-5 sm:mt-4 flex items-center justify-center">
                      <button
                        type="button"
                        className={`bg-white hover:bg-gray-50 mt-3 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:mt-0`}
                        ref={cancelButtonRef}
                        onClick={(e) => setConfirm(false)}
                      >
                        Go back
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
