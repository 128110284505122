import { Fragment, useEffect, useState } from "react";
import {
  FaceFrownIcon,
  FaceSmileIcon,
  FireIcon,
  HandThumbUpIcon,
  HeartIcon,
  PaperClipIcon,
  XMarkIcon,
  MicrophoneIcon,
  CheckIcon,
} from "@heroicons/react/20/solid";
import { Listbox, Transition } from "@headlessui/react";
import { UserAuth } from "../../context/AuthContext";
import PromptBox from "./PromptBox";
import Loading from "../common/Loading";
import ErrorAlert from "../alert/ErrorAlert";
import getErrorMessage from "../../util/error-codes";
import AudioRecord from "./UserRecordVoice";
import LinearProgress from "@mui/material/LinearProgress";
import {
  setPromptRating,
  submitResponse,
  featureCheck,
} from "../../services/api";
import PremiumContentModal from "../modal/PremiumContentModal";
import * as Constants from "util/constants";

const moods = [
  {
    name: "Excited",
    value: "excited",
    icon: FireIcon,
    iconColor: "text-white",
    bgColor: "bg-red-500",
  },
  {
    name: "Loved",
    value: "loved",
    icon: HeartIcon,
    iconColor: "text-white",
    bgColor: "bg-pink-400",
  },
  {
    name: "Happy",
    value: "happy",
    icon: FaceSmileIcon,
    iconColor: "text-white",
    bgColor: "bg-green-400",
  },
  {
    name: "Sad",
    value: "sad",
    icon: FaceFrownIcon,
    iconColor: "text-white",
    bgColor: "bg-yellow-400",
  },
  {
    name: "Liked",
    value: "liked",
    icon: HandThumbUpIcon,
    iconColor: "text-white",
    bgColor: "bg-blue-500",
  },
  {
    name: "Nothing",
    value: null,
    icon: XMarkIcon,
    iconColor: "text-gray-400",
    bgColor: "bg-transparent",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ResponseBox(props) {
  const [mood, setMood] = useState(moods[5]);
  const [content, setContent] = useState("");
  const [showPremium, setShowPremium] = useState(false);

  const [isComplete, setIsComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [prompt, setPrompt] = useState({});

  const [showAudio, setShowAudio] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioWarn, setAudioWarn] = useState(null);

  const setIsSubmitted = props.setIsSubmitted;
  const setResponseId = props.setResponseId;

  const { user } = UserAuth();
  useEffect(() => {
    //Set prompt for parent on load
    props.setPrompt(prompt);
  }, [prompt]);

  const handleFileChange = async (e) => {
    if (!(await featureCheck(user, Constants.FEATURE_FILE_UPLOAD))) {
      setShowPremium(true);
      return;
    }

    setFileList([e.target.files[0]]);
  };

  const removeFile = (e, index) => {
    e.preventDefault();
    setFileList([]);
  };

  // upload content to firebase
  const createDocument = async (e) => {
    console.log(audioBlob);
    if (audioBlob == null && fileList.length == 0 && content == "") {
      setError({ title: "Please add something to your entry!" });
      return;
    }
    e.preventDefault();
    setError(null);
    setLoading(true);

    const doc = {
      uid: user.uid,
      content: content,
      mood: mood.value,
      fileUrl: null,
      filePaths: null,
      audioUrl: null,
      audioPath: null,
      prompt: prompt ? prompt : null,
      pid: prompt?.id ? prompt.id : null,
    };

    const resp = await submitResponse(user, doc, audioBlob, fileList[0]);

    if (resp.success) {
      setResponseId(resp.meta.docId);
      setIsSubmitted(true);
      setIsComplete(true);
    } else {
      setError(resp.error);
    }
    setLoading(false);
  };

  const hideAudio = () => {
    setShowAudio(false);
  };

  const setAudio = (blob) => {
    setAudioBlob(blob);
    setAudioWarn("You will overwrite your existing audio entry!");
  };

  const showAudioModal = async () => {
    if (!(await featureCheck(user, Constants.FEATURE_RECORD))) {
      setShowPremium(true);
      return;
    }
    setShowAudio(true);
  };

  return (
    <>
      <PremiumContentModal open={showPremium} setOpen={setShowPremium} />
      <AudioRecord
        show={showAudio}
        hide={hideAudio}
        save={setAudio}
        warn={audioWarn}
      />
      {!isComplete && (
        <>
          <PromptBox promptCallback={setPrompt} />
          {error && <ErrorAlert alert={error} />}
          {progress > 0 && (
            <LinearProgress
              variant="determinate"
              color="success"
              value={progress}
              className="mb-5"
            />
          )}
          <div className="flex items-start space-x-2 pr-2 md:pb-5 md:px-1">
            <div className="hidden md:flex flex-shrink-0">
              {user?.photoURL && (
                <img
                  className="inline-block h-10 w-10 rounded-full"
                  src={user?.photoURL}
                  alt=""
                />
              )}
              {!user?.photoURL && (
                <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-500">
                  <span className="text-sm font-medium leading-none text-white">
                    U
                  </span>
                </span>
              )}
            </div>
            <div className="min-w-0 flex-1 ">
              <div action="#" className="relative">
                <div className="overflow-hidden rounded-lg shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600 h-fit">
                  <label htmlFor="content" className="sr-only">
                    Add your response...
                  </label>
                  <textarea
                    rows={6}
                    name="content"
                    id="content"
                    className="px-2 block w-full resize-y border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="Add your response..."
                    onChange={(e) => setContent(e.target.value)}
                    value={content}
                    disabled={loading}
                  />

                  {/* Spacer element to match the height of the toolbar */}
                  <div className="py-2 border-t-2" aria-hidden="true">
                    {/* Matches height of button in toolbar (1px border + 36px content height) */}
                    <div className="py-px">
                      <div className="h-9" />
                    </div>
                  </div>
                </div>

                <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2 z-1 ">
                  <div className="flex items-center space-x-5">
                    <div className="flex items-center">
                      <button
                        type="button"
                        className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                        onClick={showAudioModal}
                      >
                        {audioBlob !== null && (
                          <span className="absolute inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-green-600 rounded-full">
                            1
                          </span>
                        )}

                        <MicrophoneIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                        <span className="sr-only">Record</span>
                      </button>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="file"
                        id="file-upload"
                        onChange={handleFileChange}
                        className="hidden -m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                      />

                      <label htmlFor="file-upload" className="cursor-pointer">
                        {fileList.length > 0 && (
                          <span className="absolute inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-green-600 rounded-full">
                            {fileList.length}
                          </span>
                        )}
                        <PaperClipIcon
                          className="h-5 w-5 text-gray-400 hover:text-gray-500"
                          aria-hidden="true"
                        />

                        <span className="sr-only">Attach a file</span>
                      </label>
                    </div>
                    <div className="flex items-center z=[10000]">
                      <Listbox value={mood} onChange={setMood}>
                        {({ open }) => (
                          <>
                            <Listbox.Label className="sr-only">
                              Your mood
                            </Listbox.Label>
                            <div className="relative">
                              <Listbox.Button className="relative -m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500">
                                <span className="flex items-center justify-center">
                                  {mood.value === null ? (
                                    <span>
                                      <FaceSmileIcon
                                        className="h-5 w-5 flex-shrink-0"
                                        aria-hidden="true"
                                      />
                                      <span className="sr-only">
                                        Add your mood
                                      </span>
                                    </span>
                                  ) : (
                                    <span>
                                      <span
                                        className={classNames(
                                          mood.bgColor,
                                          "flex h-8 w-8 items-center justify-center rounded-full"
                                        )}
                                      >
                                        <mood.icon
                                          className="h-5 w-5 flex-shrink-0 text-white"
                                          aria-hidden="true"
                                        />
                                      </span>
                                      <span className="sr-only">
                                        {mood.name}
                                      </span>
                                    </span>
                                  )}
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute z-100 -ml-6 mt-1 w-60 rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:ml-auto sm:w-64 sm:text-sm">
                                  {moods.map((mood) => (
                                    <Listbox.Option
                                      key={mood.value}
                                      className={({ active }) =>
                                        classNames(
                                          active ? "bg-gray-100" : "bg-white",
                                          "relative cursor-default select-none px-3 py-2 z-10"
                                        )
                                      }
                                      value={mood}
                                    >
                                      <div className="flex items-center z-10">
                                        <div
                                          className={classNames(
                                            mood.bgColor,
                                            "flex h-8 w-8 items-center justify-center rounded-full z-10"
                                          )}
                                        >
                                          <mood.icon
                                            className={classNames(
                                              mood.iconColor,
                                              "h-5 w-5 flex-shrink-0 z-10"
                                            )}
                                            aria-hidden="true"
                                          />
                                        </div>
                                        <span className="ml-3 block truncate font-medium z-10">
                                          {mood.name}
                                        </span>
                                      </div>
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                    </div>
                  </div>
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={(e) => createDocument(e)}
                      disabled={loading}
                    >
                      {!loading && "Submit"}
                      {loading && <Loading size="h-5 w-5" />}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 ml-4 mb-8 md:ml-14 lg:ml-14">
            {fileList.map((file, index) => (
              <span
                key={index}
                className="inline-flex items-center gap-x-12 rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700  h-12"
              >
                {file.name}
                <button
                  type="button"
                  onClick={(e) => removeFile(e, index)}
                  className="group relative -mr-1 h-6 w-6 rounded-sm hover:bg-green-600/20"
                >
                  <span className="sr-only">Remove</span>
                  <svg
                    viewBox="0 0 14 14"
                    className="h-6 w-6 stroke-green-800/50 group-hover:stroke-green-800/75"
                  >
                    <path d="M4 4l6 6m0-6l-6 6" />
                  </svg>
                  <span className="absolute -inset-1" />
                </button>
              </span>
            ))}
          </div>
        </>
      )}
      {isComplete && (
        <div className="h-fill flex flex-col items-center justify-center mt-8 animate-fade-down animate-ease-out">
          <span className="bg-green-500 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white">
            <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
          </span>
          <span className="mt-4 px-4 mb-12">
            Your response has been submitted! You can see it in{" "}
            <a
              className="font-semibold underline hover:bg-green-600/20"
              href="/u/library"
            >
              your library
            </a>
            !
          </span>
        </div>
      )}
    </>
  );
}
