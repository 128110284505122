import { Fragment, useEffect, useState } from "react";
import ResponseBox from "../components/response/ResponseBox";
import UserFeed from "../components/response/UserFeed";
import SuccessfulResponse from "../components/modal/SuccessfulResponseModal";
import { UserAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Onboarding from "../components/common/Onboarding";
import { getWalkthroughFlag, setPromptRating } from "../services/api";
import RefreshModal from "../components/modal/RefreshModal";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ResponseView() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [hasTimeline, setHasTimeline] = useState(false);
  const [success, setSuccess] = useState(false);
  const [walkthrough, setWalkthrough] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [responseId, setResponseId] = useState(null);
  const { user } = UserAuth();

  const [prompt, setPrompt] = useState("");

  const navigate = useNavigate();

  const assignPromptRating = async (rating) => {
    setPromptRating(user, rating, responseId);
  };

  useEffect(() => {
    getWalkthroughFlag(user)
      .then((flag) => {
        setWalkthrough(flag);
      })
      .catch((e) => {
        setRefresh(true);
      });
  }, []);

  return (
    <>
      {<RefreshModal open={refresh} />}
      {<Onboarding show={walkthrough} setOpen={setWalkthrough} />}
      {
        <SuccessfulResponse
          open={isSubmitted}
          setPromptRating={assignPromptRating}
        />
      }
      {/* Main 3 column grid */}
      <div className="px-0 sm:px-6 lg:px-8 mt-5 lg:mt-20">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto px-2">
            <h1 className="text-2xl font-semibold leading-6 text-gray-900">
              New Entry
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Get started by answering the prompt below to create your new
              entry. This will be added to your library!
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none"></div>
        </div>

        <div className="my-10 grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8 text-black">
          {/* Right column */}
          <div
            className={`${
              hasTimeline ? "" : "sr-only"
            } grid grid-cols-1 gap-4 order-last`}
          >
            <section aria-labelledby="section-2-title">
              <h2 className="sr-only" id="section-2-title">
                Section title
              </h2>
              <div className="overflow-visible rounded-lg bg-white shadow-lg animate-fade">
                <div className="p-6">
                  <UserFeed prompt={prompt} setHasTimeline={setHasTimeline} />
                </div>
              </div>
            </section>
          </div>

          {/* Left column */}
          <div className="grid grid-cols-1 gap-4 lg:col-span-2">
            <section aria-labelledby="section-1-title">
              <h2 className="sr-only" id="section-1-title">
                Section title
              </h2>
              <div className="overflow-visible rounded-lg bg-white shadow-lg animate-fade">
                <div className="pt-5 px-2 pb-5 lg:p-6">
                  <ResponseBox
                    setPrompt={setPrompt}
                    setIsSubmitted={setIsSubmitted}
                    setResponseId={setResponseId}
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}
