import React from "react";
import { Navigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import Loading from "./Loading";
import UserNavigation from "./UserNavigation";
import Banner from "./Banner";

const ProtectedRoute = ({ children }) => {
  const { user } = UserAuth();

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (Object.keys(user).length === 0) {
    return (
      <>
        <UserNavigation />
        <Banner />
        <div className="h-screen flex flex-col items-center justify-center">
          <Loading />
        </div>
      </>
    );
  }

  if (!user.emailVerified && window.location.pathname !== "/u/verify-email") {
    // navigate('/u/home')
    return <Navigate to="/u/verify-email" />;
  }

  return children;
};

export default ProtectedRoute;
