import { Fragment, useState, useEffect } from "react";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  MinusIcon,
} from "@heroicons/react/20/solid";
import {
  BookOpenIcon,
  FaceSmileIcon,
  FaceFrownIcon,
  FireIcon,
  InformationCircleIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";

import { useNavigate } from "react-router-dom";
import * as Constants from "util/constants";
import {
  getSentimentScoreLabel,
  getSentimentMagnitudeLabel,
  formatDate,
} from "util/helpers";
import InfoModal from "components/modal/InfoModal";
import Skeleton from "@mui/material/Skeleton";
import { Disclosure, Menu, Transition } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function formMetrics(metrics) {
  console.log(metrics);
  return [
    {
      id: 1,
      name: "Total Pages",
      //round up to no decimal places
      stat: Math.ceil(metrics.totalWords / 250),
      textSize: "text-2xl",
      icon: BookOpenIcon,
      textColor: "text-gray-900",
      helpText: "The number of pages your responses could fill.",
      // change: "0 pages",
      // changeType: "none",
    },
    {
      id: 2,
      name: "Avg. Emotion Rating",
      stat: getSentimentScoreLabel(metrics.avgSentimentScore),
      textSize: "text-2xl",
      icon: FaceSmileIcon,
      // change: "",
      // changeType: "increase",
      textColor: "text-gray-900",
      helpText: "Balanced assessment on positive/negative entries",
    },
    {
      id: 3,
      name: "Avg. Emotion Intensity",
      textSize: "text-2xl",
      stat: getSentimentMagnitudeLabel(metrics.avgSentimentMagnitude),
      icon: FireIcon,
      // change: "",
      // changeType: "decrease",
      textColor: "text-gray-900",
      helpText:
        "The intensity of your responses, the more emotional, the more intense.",
    },
  ];
}

export default function Stats(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const navigate = useNavigate();
  const metrics = props.metrics;
  const locked = props.locked;
  const loading = props.isLoading;

  if (loading) {
    return (
      <>
        <div className="my-5 ">
          <Skeleton
            variant="rectangular"
            animation="wave"
            className="relative block w-full rounded-lg border-2 border-gray-300 px-12 py-20 text-center"
          />
        </div>
      </>
    );
  }

  if (locked) {
    return (
      <>
        <div className="my-5 ">
          <button
            type="button"
            onClick={() => navigate(Constants.ROUTES.USER_UPGRADE)}
            className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <LockClosedIcon
              className="mx-auto h-12 w-12 text-gray-400"
              aria-hidden="true"
            />
            <span className="mt-2 block text-sm font-semibold text-gray-900">
              Upgrade to unlock this feature
            </span>
          </button>
        </div>
      </>
    );
  }

  if (!metrics) {
    return (
      <>
        <div className="my-5 ">
          <button
            type="button"
            onClick={() => navigate(Constants.ROUTES.USER_WRITE)}
            className="relative block w-full rounded-lg border-2 border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <BookOpenIcon
              className="mx-auto h-12 w-12 text-gray-400"
              aria-hidden="true"
            />
            <span className="mt-2 block text-sm font-semibold text-gray-900">
              Add to your journal and see your stats here!
            </span>
          </button>
        </div>
      </>
    );
  }

  const openModal = (title, content) => {
    setTitle(title);
    setContent(content);
    setIsOpen(true);
  };

  const stats = formMetrics(metrics);
  return (
    <>
      <InfoModal
        open={isOpen}
        setOpen={setIsOpen}
        title={title}
        content={content}
      />
      <div className="flow-root animate-fade">
        <h3 className="text-base font-semibold leading-6 text-gray-900"></h3>

        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {stats.map((item) => (
            <div
              key={item.id}
              className="relative overflow-hidden rounded-lg bg-white px-4 pb-5 pt-5 shadow sm:px-6 sm:pt-6 ring-1 ring-black ring-opacity-5 sm:rounded-lg"
            >
              <dt>
                <div
                  onClick={() => openModal(item.name, item.helpText)}
                  className="absolute rounded-md bg-indigo-500 p-3 hover:bg-indigo-700 hover:cursor-pointer"
                  aria-hidden="true"
                >
                  <item.icon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </div>
                <p className="ml-16 truncate text-sm font-medium text-gray-500">
                  {item.name}
                </p>
              </dt>
              <dd className="ml-16 flex items-baseline pb-6 sm:pb-7 has-tooltip">
                <p
                  className={`${item.textSize} font-semibold ${item.textColor}`}
                >
                  {item.stat}
                </p>

                <p
                  className={classNames(
                    item.changeType === "increase"
                      ? "text-green-600"
                      : item.changeType === "decrease"
                        ? "text-red-600"
                        : "text-gray-600",
                    `${item.textColor} ml-2 flex items-baseline text-sm font-semibold`
                  )}
                >
                  {
                    item.changeType === "increase" ? (
                      <ArrowUpIcon
                        className="h-5 w-5 flex-shrink-0 self-center text-green-500"
                        aria-hidden="true"
                      />
                    ) : item.changeType === "decrease" ? (
                      <ArrowDownIcon
                        className="h-5 w-5 flex-shrink-0 self-center text-red-500"
                        aria-hidden="true"
                      />
                    ) : (
                      <></>
                    )
                    //  (
                    //   <MinusIcon
                    //     className="h-5 w-5 flex-shrink-0 self-center text-gray-500"
                    //     aria-hidden="true"
                    //   />
                    // )
                  }

                  <span className="sr-only">
                    {" "}
                    {item.changeType === "increase"
                      ? "Increased"
                      : item.changeType === "decrease"
                        ? "Decreased"
                        : "Unchanged"}{" "}
                    by{" "}
                  </span>
                  {item.change}
                </p>
                <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-2 sm:px-6">
                  <div className="text-sm"></div>
                </div>
              </dd>
            </div>
          ))}
        </dl>
        <p className="mt-2 text-sm text-gray-700 italic">
          Last 60 days, updated: {formatDate(metrics.system.createdAt)}
        </p>
      </div>
    </>
  );
}
