import { Fragment, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  CodeBracketIcon,
  EllipsisVerticalIcon,
  FlagIcon,
  StarIcon,
  ArrowPathIcon,
} from "@heroicons/react/20/solid";
import { UserAuth } from "../../context/AuthContext";
import PromptGenerationModal from "../modal/PromptGenerationModal";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import { getPrompt, setHelp, featureCheck } from "../../services/api";
import PremiumContentModal from "../modal/PremiumContentModal";
import * as Constants from "util/constants";
import { formatDate } from "util/helpers";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PromptBox(props) {
  const [prompt, setPrompt] = useState({});
  const [showNewPromptModal, setShowNewPromptModal] = useState(false);
  const [showPremium, setShowPremium] = useState(false);
  const { user } = UserAuth();

  useEffect(() => {
    if (user) {
      getPrompt(user)
        .then((prompt) => {
          setPrompt(prompt);
          //Send data to parent component
          props.promptCallback(prompt);
        })
        .catch((e) => {});
    }
  }, [user]);

  const checkNewPrompt = async () => {
    if (!(await featureCheck(user, Constants.FEATURE_GENERATE_PROMPT))) {
      setShowPremium(true);
      return;
    }

    setShowNewPromptModal(true);
  };

  const reportContent = async () => {
    if (!window.confirm("Do you want to report this prompt?")) {
      return;
    }
    const doc = {
      uid: user.uid,
      prompt: prompt,
      status: "new",
      subject: "Prompt content reported",
      message: "The user reported content that was supplied to them.",
    };

    await setHelp(user, doc);
    alert("Thank you for reporting this prompt.");
  };

  const dateWindow = new Date();
  dateWindow.setDate(dateWindow.getDate() - 2);

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
      <PremiumContentModal open={showPremium} setOpen={setShowPremium} />
      <PromptGenerationModal
        open={showNewPromptModal}
        setOpen={setShowNewPromptModal}
      />
      <div className="min-h-fill text-black">
        <div className="-mt-8">
          <div className="mx-auto max-w-7xl px-0 pb-1 sm:px-0 lg:px-0 md:py-5 md:px-1">
            {/* Your content */}
            <div className="px-0 py-5 sm:px-0">
              <div className="flex space-x-3">
                <div className="hidden md:flex flex-shrink-0">
                  <img
                    className="h-10 w-10 rounded-full"
                    src="/sayso-logo.png"
                    alt=""
                  />
                </div>
                <div className="min-w-0 flex-1 pl-1">
                  <p className="text-sm font-semibold text-gray-900 mt-2">
                    <a href="#" className="hover:underline">
                      SaySo Project
                    </a>

                    {prompt.assigned_date?.toDate() > dateWindow && (
                      <span className="ml-2 inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
                        New Prompt
                      </span>
                    )}
                  </p>
                  <div className="text-sm text-gray-500">
                    <a href="#" className="hover:underline">
                      {prompt.assigned_date ? (
                        formatDate(prompt.assigned_date)
                      ) : (
                        <Box sx={{ width: 200 }}>
                          <Skeleton animation="wave" />
                        </Box>
                      )}
                    </a>
                  </div>
                  <div className="text-m text-gray-900 pt-5 pb-5">
                    <span>
                      {prompt.prompt ? (
                        prompt.prompt
                      ) : (
                        <Box sx={{ width: 300 }}>
                          <Skeleton animation="wave" />
                        </Box>
                      )}
                    </span>
                  </div>
                </div>
                <div className="flex flex-shrink-0 self-center">
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-500 hover:text-gray-600">
                        <span className="sr-only">Open options</span>
                        <EllipsisVerticalIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                onClick={(e) => checkNewPrompt()}
                                href="#"
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "flex px-4 py-2 text-sm"
                                )}
                              >
                                <ArrowPathIcon
                                  className="mr-3 h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                                <span>Request new prompt</span>
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                onClick={(e) => reportContent()}
                                href="#"
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "flex px-4 py-2 text-sm"
                                )}
                              >
                                <FlagIcon
                                  className="mr-3 h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                                <span>Report content</span>
                              </a>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
