export default function EULA() {
    return (
      <div className="bg-white px-6 py-24 sm:py-32 lg:px-8">
   <div className="mx-auto max-w-2xl">
      <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl text-center">End User License Agreement (EULA)</h2>
      <p className="mt-6 text-lg leading-8 text-gray-600">
         This End User License Agreement ("Agreement") is a legal agreement between you ("User") and Craft IT ("Company") regarding the use of SaySo Project ("App"). By downloading, installing, or using the App, User agrees to be bound by the terms and conditions of this Agreement.
      </p>
      <h3 className="mt-8 text-2xl font-semibold text-gray-900">1. Grant of License</h3>
      <p className="mt-4 text-lg leading-8 text-gray-600">
         Subject to the terms and conditions of this Agreement, Company grants User a limited, non-exclusive, non-transferable license to use the App for personal, non-commercial purposes in accordance with the documentation and any applicable usage rules or guidelines.
      </p>
      <h3 className="mt-8 text-2xl font-semibold text-gray-900">2. Intellectual Property Rights</h3>
      <p className="mt-4 text-lg leading-8 text-gray-600">
         The App and all related intellectual property rights are owned by Company and are protected by intellectual property laws. User acknowledges and agrees that they do not acquire any ownership rights or interests in the App or its intellectual property under this Agreement.
      </p>
      <h3 className="mt-8 text-2xl font-semibold text-gray-900">3. Restrictions</h3>
      <p className="mt-4 text-lg leading-8 text-gray-600">
         User shall not, and shall not allow any third party to:
      </p>
      <ul className="list-disc ml-8 mt-4 text-lg leading-8 text-gray-600">
         <li>Copy, modify, or distribute the App, in whole or in part, without Company's prior written consent.</li>
         <li>Reverse engineer, decompile, or disassemble the App.</li>
         <li>Remove, alter, or obscure any copyright, trademark, or other proprietary notices from the App.</li>
         <li>Create derivative works based on the App.</li>
         <li>Use the App for any unlawful purpose or in any manner that violates this Agreement.</li>
      </ul>
      <h3 className="mt-8 text-2xl font-semibold text-gray-900">4. Disclaimer of Warranty</h3>
      <p className="mt-4 text-lg leading-8 text-gray-600">
         The App is provided "as is" without warranty of any kind. Company disclaims all warranties, whether express, implied, statutory, or otherwise, including but not limited to any warranties of merchantability, fitness for a particular purpose, and non-infringement.
      </p>
      <h3 className="mt-8 text-2xl font-semibold text-gray-900">5. Limitation of Liability</h3>
      <p className="mt-4 text-lg leading-8 text-gray-600">
         In no event shall Company be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with the use or inability to use the App, even if Company has been advised of the possibility of such damages. In no event shall Company's total liability to User exceed the amount paid, if any, by User for the App.
      </p>
      <h3 className="mt-8 text-2xl font-semibold text-gray-900">6. Termination</h3>
      <p className="mt-4 text-lg leading-8 text-gray-600">
         This Agreement is effective until terminated. User may terminate this Agreement by deleting the App from their device. Company may terminate this Agreement at any time without notice if User fails to comply with any term of this Agreement. Upon termination, User shall cease all use of the App and destroy any copies in their possession.
      </p>
      <h3 className="mt-8 text-2xl font-semibold text-gray-900">7. Governing Law</h3>
      <p className="mt-4 text-lg leading-8 text-gray-600">
         This Agreement shall be governed by and construed in accordance with the laws of [Jurisdiction]. Any disputes arising under or in connection with this Agreement shall be subject to the exclusive jurisdiction of the courts located in [Jurisdiction].
      </p>
      <h3 className="mt-8 text-2xl font-semibold text-gray-900">8. Entire Agreement</h3>
      <p className="mt-4 text-lg leading-8 text-gray-600">
         This Agreement constitutes the entire agreement between User and Company regarding the use of the App and supersedes all prior agreements and understandings, whether written or oral.
      </p>
      <h3 className="mt-8 text-2xl font-semibold text-gray-900">9. Contact Information</h3>
      <p className="mt-4 text-lg leading-8 text-gray-600">
         If you have any questions or concerns about this Agreement, please contact us at help@sayso-project.com.
      </p>
   </div>
</div>

    )
  }
  