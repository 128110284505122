
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { StarIcon } from '@heroicons/react/24/outline'
import { StarIcon as SolidStarIcon } from '@heroicons/react/24/solid'

export default function StarRating(props) {

    const [number, setNumber] = useState(0)


    const setRating = props.setRating;

    const setValue = (value) => {
        setNumber(value);
        setRating(value)
    }

    const n = 5;
    var stars = [];
    for(var i = 1; i <= n; i++) {
        stars.push(i <= number)
    }

    return (
        <>
            {
                stars.map((item, key) => {
                    return (item ? <SolidStarIcon key={key} className="h-6 w-6 text-yellow-600 animate-ease-i cursor-pointer" aria-hidden="true" onClick={(e) => setValue(key+1)} />
                        : <StarIcon key={key} className="h-6 w-6 text-yellow-600 animate-ease-in cursor-pointer" aria-hidden="true"  onClick={(e) => setValue(key+1)}/>)
                })
            }
        </>
                    
    )
}
