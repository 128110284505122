import { XCircleIcon, CheckCircleIcon } from "@heroicons/react/20/solid";

export default function Alert(props) {
  const selectColor = (type) => {
    if (type === "success") {
      return "green";
    }
    return "red";
  };

  const selectIcon = (type) => {
    if (type === "success") {
      return CheckCircleIcon;
    } else {
      return XCircleIcon;
    }
  };
  const alert = props.alert;
  const title = alert.title;
  const messages = alert.messages;
  const color = alert.color;
  const Icon = selectIcon(alert ? alert.type : props.type);
  console.log(color);
  return (
    <div className={`rounded-md bg-${color}-50 p-4 mb-5`}>
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <Icon className={`h-5 w-5 text-${color}-400`} aria-hidden="true" />
        </div>
        <div className="ml-3 flex items-center">
          <h3 className={`text-sm font-medium text-${color}-800`}>{title}</h3>
          {messages && (
            <div className={`mt-2 text-sm text-${color}-700`}>
              <ul className="list-disc space-y-1 pl-5">
                {messages.map((message) => (
                  <li key={message}>{message}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
