import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'

const frequencies = [
  { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
  { value: 'annually', label: 'Annually', priceSuffix: '/year' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Product(props) {
  const [frequency, setFrequency] = useState(frequencies[0])

  const tiers = props.products
  const checkout = props.checkout
  const disabled = props.disabled

  return (
    <>
    <div className="pb-24 ">
        <div className="flex justify-center">
            <RadioGroup
                value={frequency}
                onChange={setFrequency}
                className="shadow-xl bg-white px-5 grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
            >
                <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
                {frequencies.map((option) => (
                <RadioGroup.Option
                    key={option.value}
                    value={option}
                    className={({ checked }) =>
                    classNames(
                        checked ? 'bg-indigo-600 text-white' : 'text-gray-500',
                        'cursor-pointer rounded-full px-2.5 py-1'
                    )
                    }
                >
                    <span>{option.label}</span>
                </RadioGroup.Option>
                ))}
            </RadioGroup>
            </div>
            
            <div className="mx-auto max-w-7xl px-6 lg:px-8 mt-12">
            <div className="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-4xl lg:grid-cols-2">
              {tiers.map((tier) => (
                <div
                  key={tier.id}
                  className="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10"
                >
                  <div>
                    <h3 id={tier.id} className="text-base font-semibold leading-7 text-indigo-600">
                      {tier.name}
                    </h3>
                    <div className="mt-4 flex items-baseline gap-x-2">
                      <span className="text-5xl font-bold tracking-tight text-gray-900">{tier.price[frequency.value].value}</span>
                      <span className="text-base font-semibold leading-7 text-gray-600">{frequency.priceSuffix}</span>
                    </div>
                    <p className="mt-6 text-base leading-7 text-gray-600">{tier.description}</p>
                    <ul role="list" className="mt-10 space-y-4 text-sm leading-6 text-gray-600">
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex gap-x-3">
                          <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <button
                    onClick={(e) => {checkout(tier.price[frequency.value].id)}}
                    aria-describedby={tier.id}
                    className={`mt-8 block rounded-md  px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm ${tier.active ? 'bg-indigo-200' : 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'}`}
                    disabled={tier.active}
                  >
                    {tier.active ? 'Already subscribed' : 'Purchase'}
                  </button>
                </div>
              ))}
              
            </div>
          </div>
        </div>
    </>
  )
}
