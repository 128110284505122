
import { Fragment, useState } from 'react'
import {UserAuth} from '../context/AuthContext'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function getInputClassNames(){
  return "block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
}

export default function ProfileView() {
  const {user} = UserAuth();

  return (
    <>
      <div>
        <div className="xl:pl-72">
          <main>
            <div >
              <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-4 lg:py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                <div>
                  <h2 className="text-base font-semibold leading-7 text-gray-500">Personal Information</h2>
                  <p className="mt-1 text-sm leading-6 text-gray-400">
                    Update your details to ensure you get the most out of SaySo.
                  </p>
                </div>

                <form className="md:col-span-2">
                  <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                    <div className="col-span-full flex items-center gap-x-8">
                      
                      {user?.photoURL && <img
                        className="h-24 w-24 flex-none rounded-lg bg-gray-800 object-cover"
                        src={user?.photoURL}
                        alt=""
                      />
                      }
                      {
                        !user?.photoURL && 
                        <span className="inline-flex items-center justify-center h-24 w-24 flex-none rounded-lg bg-gray-800 object-cover">
                        <span className="text-sm font-medium leading-none text-white">No Picture</span>
                        </span> 

                      }
                    </div>

                    <div className="col-span-full">
                      <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-500">
                        Email address
                      </label>
                      <div className="mt-2">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          className={getInputClassNames()}
                          value={user.email}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}
