import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/20/solid'
import { type } from '@testing-library/user-event/dist/type';

export default function Alert(props) {

  

    const alert = props.alert;
    const title = alert.title;
    const messages = alert.messages;
    const Icon = XCircleIcon;
  return (
    <div className={`rounded-md bg-red-50 p-4 mb-5`}>
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <Icon className={`h-5 w-5 text-red-400`} aria-hidden="true" />
        </div>
        <div className="ml-3 flex items-center">
          <h3 className={`text-sm font-medium text-red-800`}>{title}</h3>
          {/* {messages && <div className={`mt-2 text-sm text-red-700`}>
            <ul role="list" className="list-disc space-y-1 pl-5">
                {messages.map((message) => (
                    <li key={message}>{message}</li>
                ))}
            </ul>
          </div>} */}
        </div>
      </div>
    </div>
  )
}
