import { Fragment, useEffect, useState } from "react";
import {
  CheckIcon,
  HandThumbUpIcon,
  UserIcon,
} from "@heroicons/react/20/solid";
import { UserAuth } from "../../context/AuthContext";
import { format } from "date-fns";
import { getPromptResponseHistory } from "services/api";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function UserFeed(props) {
  const [timeline, setTimeline] = useState(null);
  const { user } = UserAuth();

  const setHasTimeline = props.setHasTimeline;

  const getFormattedDate = (date) => {
    try {
      return format(date.toDate(), "MMMM d");
    } catch (e) {
      return "";
    }
  };

  //Creates Feed Items
  const createFeed = (history, prompt) => {
    const feed = [];
    //Create initial feed
    var seed = {
      id: history.length,
      content: "SaySo sent you",
      target: "a new prompt!",
      href: "#",
      date: getFormattedDate(prompt.assigned_date),
      icon: UserIcon,
      iconBackground: "bg-gray-400",
      // seed: true, // set to true if sayso logo should appear
    };

    history.forEach((item, i) => {
      console.log(item);
      const bolded =
        item.isAudio && item.isFile && item.isText
          ? "full response"
          : item.isAudio && item.isFile
            ? "voice and file response"
            : item.isAudio && item.isText
              ? "voice and text response"
              : item.isFile && item.isText
                ? "file and text response"
                : item.isFile
                  ? "file response"
                  : item.isAudio
                    ? "voice response"
                    : "text response";

      const n = {
        id: i,
        content: "You added a",
        target: bolded,
        href: "#",
        date: getFormattedDate(item.timestamp),
        icon: CheckIcon,
        iconBackground: "bg-green-500",
      };
      feed.push(n);
    });
    feed.push(seed);
    return feed;
  };

  useEffect(() => {
    const prompt = props.prompt;
    if (!prompt) {
      return;
    }
    if (prompt.id && timeline == null) {
      getPromptResponseHistory(user, prompt.id).then((resp) => {
        const feed = createFeed(resp, prompt);
        if (feed.length > 0) {
          setHasTimeline(true);
        }
        setTimeline(feed);
      });
    }
  }, [props.prompt]);

  return (
    <>
      <div className="flow-root animate-fade">
        <ul role="list" className="-mb-8">
          {timeline?.map((event, eventIdx) => (
            <li key={event.id}>
              <div className="relative pb-8">
                {eventIdx !== timeline.length - 1 ? (
                  <span
                    className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="relative flex space-x-3 animate-fade">
                  <div>
                    <span
                      className={classNames(
                        event.iconBackground,
                        "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                      )}
                    >
                      {event.seed ? (
                        <img
                          className="h-8 w-8 rounded-full"
                          src="/sayso-logo.png"
                          alt=""
                        />
                      ) : (
                        <event.icon
                          className="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      )}
                    </span>
                  </div>
                  <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                    <div>
                      <p className="text-sm text-gray-500">
                        {event.content}{" "}
                        <a
                          href={event.href}
                          className="font-medium text-gray-900"
                        >
                          {event.target}
                        </a>
                      </p>
                    </div>
                    <div className="whitespace-nowrap text-right text-sm text-gray-500">
                      <time dateTime={event.datetime}>{event.date}</time>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
