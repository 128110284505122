import { getApp } from "@firebase/app";
import {
  getStripePayments,
  createCheckoutSession,
  getCurrentUserSubscriptions,
  getCurrentUserSubscription,
} from "@stripe/firestore-stripe-payments";

import { useState, useEffect } from "react";
import { getFunctions, httpsCallable } from "@firebase/functions";
import Loading from "../components/common/Loading";
import Modal from "../components/modal/BillingCheckModal";
import CurrentPlan from "../components/subscription/BillingCurrentPlan";
import ErrorModal from "../components/modal/ErrorModal";
import ProductItem from "../components/subscription/ProductItem";
import {
  getProductList,
  getUserSubscription,
  getCustomerPortalURL,
  createCustomerCheckoutSession,
} from "../services/api.js";
import { UserAuth } from "../context/AuthContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const app = getApp();
const payments = getStripePayments(app, {
  productsCollection: "stripe_products",
  customersCollection: "stripe_customers",
});

const error = {
  title: "Something went wrong!",
  message:
    "Looks like something didn't work. Get in contact with us if this is a recurring issue.",
};

export default function Billing() {
  const [isInSession, setIsInSession] = useState(false);
  const [products, setProducts] = useState([]);
  const [openModal, setModalOpen] = useState(false);
  const [subscription, setSubscription] = useState({
    name: "",
    description: "",
    active: false,
  });
  const [mounted, setMounted] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);

  //Hooks
  const { user } = UserAuth();

  // Membership product
  const product = products ? products[0] : null;

  // FUNCTION: Get Stripe Customer Portal URL
  const queryCustomerPortalURL = async () => {
    try {
      const data = await getCustomerPortalURL();
      return data;
    } catch (e) {
      setOpenErrorModal(true);
    }
  };

  const queryIndividualSubscription = async () => {
    const sub = await getUserSubscription(user);
    return sub;
  };

  const queryProducts = async () => {
    const productList = await getProductList(user);
    return productList;
  };

  // FUNCTION: Format price
  const getPrice = (product) => {
    if (product === null) return;
    if (product.prices.length === 0) return "Free";

    const price = product?.prices[0];
    const amount = price?.unit_amount / 100;
    return `${amount.toFixed(2)}`;
  };

  const getCurrency = (product) => {
    if (product === null) return;
    return product.prices[0].currency.toUpperCase();
  };

  const checkout = async (priceId) => {
    setIsInSession(true);
    setModalOpen(true);
    console.log("Working...");
    try {
      const session = await createCustomerCheckoutSession(priceId);
      setIsInSession(false);
      setModalOpen(false);
      window.location.href = session.url;
    } catch (e) {
      console.log(e);
      setOpenErrorModal(true);
    } finally {
      setModalOpen(false);
    }
  };

  //FUNCTION: Navigate to Stripe
  const navigateToStripe = async () => {
    setModalOpen(true);
    try {
      const data = await queryCustomerPortalURL();
      window.location.href = data.url;
    } catch (e) {
      setOpenErrorModal(true);
    } finally {
      setModalOpen(false);
    }
  };

  // MOUNTED: When rendered, acquire data
  useEffect(() => {
    if (!mounted) {
      queryProducts().then((products) => {
        setProducts(products);
      });

      queryIndividualSubscription().then((sub) => {
        setSubscription(sub);
      });

      setMounted(true);
    }
  }, []);

  return (
    <>
      <Modal open={openModal} setOpen={setModalOpen} />
      <div className="py-24 sm:py-18">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl sm:text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Subscription
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600"></p>
          </div>
        </div>
        <div className="mt-16">
          <CurrentPlan
            redirect={navigateToStripe}
            subscription={subscription}
          />
        </div>
        <div className="mt-12">
          <ProductItem
            products={products}
            checkout={checkout}
            disabled={subscription.active}
          />
        </div>
      </div>

      <ErrorModal
        open={openErrorModal}
        setOpen={setOpenErrorModal}
        title={error.title}
        message={error.message}
      />
    </>
  );
}
