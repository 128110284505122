import { format } from "date-fns";
import * as Constants from "./constants";

export const formatDate = (date) => {
  try {
    return format(date.toDate(), "MMMM do, yyyy H:mm a");
  } catch (e) {
    return "";
  }
};

export const getSentimentScoreLabel = (score) => {
  for (const label in Constants.METRIC_SENTIMENT_SCORE_MAP) {
    if (
      score >= Constants.METRIC_SENTIMENT_SCORE_MAP[label].lower &&
      score < Constants.METRIC_SENTIMENT_SCORE_MAP[label].upper
    ) {
      return label;
    }
  }
  return null; // or any default label
};

//Give label for sentiment magnitude
export const getSentimentMagnitudeLabel = (magnitude) => {
  for (const label in Constants.METRIC_SENTIMENT_MAGNITUDE_MAP) {
    if (
      magnitude >= Constants.METRIC_SENTIMENT_MAGNITUDE_MAP[label].lower &&
      magnitude < Constants.METRIC_SENTIMENT_MAGNITUDE_MAP[label].upper
    ) {
      return label;
    }
  }
  return null; // or any default label
};
