export default function Disclosure() {
  return (
    <>
      <p className="mt-10 text-center text-sm text-gray-500">
        This site is protected by reCAPTCHA and the Google
      </p>
      <p className="text-center text-sm text-gray-500">
        <a
          href="https://policies.google.com/privacy"
          className="underline cursor:pointer hover:underline"
          title="Privacy Policy"
        >
          {" "}
          Privacy Policy
        </a>{" "}
        and
        <a
          href="https://policies.google.com/terms"
          className="underline cursor:pointer hover:underline"
          title="Terms of Service"
        >
          {" "}
          Terms of Service
        </a>{" "}
        apply.
      </p>
    </>
  );
}
