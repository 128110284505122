import { Fragment, useEffect, useRef, useState, Suspense, lazy } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  MicrophoneIcon,
  PauseIcon,
  CheckIcon,
  StopIcon,
  PlayIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import { ClockIcon } from "@heroicons/react/24/outline";
import { UserAuth } from "../../context/AuthContext";
import Alert from "../alert/Alert";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Loading from "../common/Loading";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";

const LiveAudioVisualizer = lazy(async () => {
  const { LiveAudioVisualizer } = await import("react-audio-visualize");
  return { default: LiveAudioVisualizer };
});

export default function RecordAudio(props) {
  const [active, setActive] = useState(false);
  const [paused, setPaused] = useState(false);
  const [time, setTime] = useState(null);

  const show = props.show;
  const hide = props.hide;
  const save = props.save;
  const warn = props.warn;
  const cancelButtonRef = useRef(null);

  const {
    startRecording,
    stopRecording,
    togglePauseResume,
    recordingBlob,
    isRecording,
    isPaused,
    recordingTime,
    mediaRecorder,
  } = useAudioRecorder();

  const back = () => {
    hide();
  };

  const pause = () => {
    togglePauseResume();
    setPaused(true);
  };

  const resume = () => {
    togglePauseResume();
    setPaused(false);
  };

  const start = () => {
    startRecording();
    setTime(null);
    setActive(true);
    setPaused(false);
  };

  const stop = () => {
    setActive(false);
    setPaused(false);
    setTime(recordingTime);
    stopRecording();
  };

  const destroy = () => {
    setActive(false);
    stopRecording();
  };

  const saveRecording = () => {
    stopRecording();
    setActive(false);
    setPaused(false);

    //do not save here as recordingBlob is null, state needs to be updated first
    // save(recordingBlob)
  };

  // Interesting audio component behaviour means that recordingBlob
  //  can only be passed back once state has updated after 'stopRecording()' fn
  //  therefore, need to watch state changes and save accordingly. Seems OK
  useEffect(() => {
    if (!recordingBlob) return;

    save(recordingBlob);
    hide();
  }, [recordingBlob]);

  const pad = (n, size) => {
    var num = n.toString();
    while (num.length < size) num = "0" + num;
    return num;
  };

  return (
    <>
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={hide}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-3 sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base text-center font-semibold leading-6 text-gray-900 mb-2"
                      >
                        Record your journal entry
                      </Dialog.Title>
                      {
                        <Dialog.Description className="text-base text-center text-sm font-semibold text-red-500 mb-5">
                          {warn}
                        </Dialog.Description>
                      }
                      <hr />
                      <div className="flex justify-center my-5">
                        {/* <CheckIcon onClick={saveRecording} className="h-10 w-10" aria-hidden="true" /> */}
                        {
                          <div>
                            <div>
                              <span className={`audio-recorder-visualizer h-8`}>
                                {mediaRecorder && (
                                  <Suspense fallback={<></>}>
                                    <>
                                      <LiveAudioVisualizer
                                        mediaRecorder={mediaRecorder}
                                        barWidth={2}
                                        gap={2}
                                        width={140}
                                        height={30}
                                        fftSize={512}
                                        maxDecibels={-10}
                                        minDecibels={-80}
                                        smoothingTimeConstant={0.4}
                                      />
                                    </>
                                  </Suspense>
                                )}
                              </span>
                            </div>
                            {
                              <div className="flex justify-center items-center my-1">
                                <ClockIcon className="h-4 w-4" />
                                &nbsp;
                                <span>
                                  {pad(Math.floor(recordingTime / 60), 2)} :{" "}
                                  {pad(recordingTime % 60, 2)}
                                </span>
                              </div>
                            }
                          </div>
                        }
                      </div>
                      <hr />
                      <div className="my-4 sm:my-4">
                        {!active && !paused && (
                          <button
                            type="button"
                            className="inline-flex w-full justify-center items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={start}
                          >
                            <MicrophoneIcon
                              className="h-4 w-4"
                              aria-hidden="true"
                            />
                            &nbsp;Record
                          </button>
                        )}
                        {!paused && active && (
                          <button
                            type="button"
                            className="mt-3 inline-flex items-center w-full justify-center rounded-md bg-white hover:bg-gray-50 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                            onClick={pause}
                          >
                            <PauseIcon className="h-4 w-4" aria-hidden="true" />
                            &nbsp;Pause
                          </button>
                        )}
                        {paused && active && (
                          <button
                            type="button"
                            className="mt-3 inline-flex items-center w-full justify-center rounded-md bg-white hover:bg-gray-50 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                            onClick={resume}
                          >
                            <PlayIcon className="h-4 w-4" aria-hidden="true" />
                            &nbsp;Continue
                          </button>
                        )}
                      </div>
                      {/* <div className="my-4 sm:my-4">
                      {<button
                        type="button"
                        className={`${active ? 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600' : 'bg-indigo-300'} inline-flex w-full justify-center items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm`}
                        onClick={stop}
                        disabled={!active}
                      >
                        <StopIcon className="h-4 w-4" aria-hidden="true" />
                        &nbsp;Stop
                      </button>}
                      
                    </div> */}
                      <hr />
                      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                        <button
                          type="button"
                          className={`${
                            paused
                              ? "focus-visible:outline-indigo-600 bg-indigo-600 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                              : "bg-indigo-300"
                          } inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm sm:col-start-2`}
                          onClick={saveRecording}
                          disabled={!paused}
                        >
                          Save & Add
                        </button>
                        <button
                          type="button"
                          className={`${
                            active && !paused
                              ? "bg-gray-100 text-gray-700"
                              : "bg-white hover:bg-gray-50 text-gray-900 hover:bg-gray-50"
                          } mt-3 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 sm:col-start-1 sm:mt-0`}
                          onClick={back}
                          disabled={active && !paused}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
