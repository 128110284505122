import { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithRedirect,
  getRedirectResult,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "@firebase/auth";

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});

  const createUser = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const signIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    return signInWithRedirect(auth, provider);
  };

  const logout = () => {
    return signOut(auth);
  };

  const handleRedirect = () => {
    return getRedirectResult(auth);
  };

  //   const actionCodeSettings = {
  //     // URL you want to redirect back to. The domain (www.example.com) for
  //     // this URL must be whitelisted in the Firebase Console.
  //     url: process.env.REACT_APP_MEASUREMENT_ID,
  //     // This must be true for email link sign-in.
  //     handleCodeInApp: false,
  //   };

  const sendUserEmailVerification = () => {
    return sendEmailVerification(auth.currentUser);
  };

  const sendUserPasswordResetEmail = (email) => {
    return sendPasswordResetEmail(auth, email);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider
      value={{
        createUser,
        user,
        logout,
        signIn,
        signInWithGoogle,
        handleRedirect,
        sendUserEmailVerification,
        sendUserPasswordResetEmail,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
