import * as React from "react";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import PropTypes from "prop-types";
import { UserAuth } from "../../context/AuthContext";
import { setWalkthrough, setJoiningReason } from "../../services/api";

const steps = [
  {
    image: "/assets/hello-illustration.png",
    title: "Welcome to SaySo!",
    body: "You've taken the first step in supporting your mental wellbeing. We're excited to have you on SaySo!",
  },
  {
    image: "/assets/idea-illustration.png",
    title: "Your personalised prompts",
    body: "Each week, we'll send you personalised prompts, be sure to respond to these when you feel comfortable doing so.",
  },
  {
    image: "/assets/writing-illustration.png",
    title: "Your journal responses",
    body: "When you submit responses, they're added to your library where you can look back on them!",
  },
  {
    image: "/assets/ready-illustration.png",
    title: "What brings you to SaySo?",
    body: "Select all that apply, we're keen to know more!",
    action: true,
    items: [
      "Self Discovery",
      "Reflection",
      "Personal Growth",
      "Mental Health",
      "Tracking Mood",
      "Therapeutic Expression",
    ],
  },
  {
    image: "/assets/ready-illustration.png",
    title: "You're ready to SaySo!",
    body: "That's it! Let's get started!",
  },
];

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

export default function OnboardingFlow(props) {
  const [activeStep, setActiveStep] = useState(0);
  const [selected, setSelected] = useState([]);

  const show = props.show ? true : false;

  const { user } = UserAuth();

  const updateWalkthrough = async () => {
    await setWalkthrough(user, false);
    await setJoiningReason(user, selected);
  };

  const toggleItemSelection = (value) => {
    const i = selected.indexOf(value);
    if (i > -1) {
      //remove
      setSelected(selected.filter((item, i) => item !== value));
    } else {
      //add
      setSelected([...selected, value]);
    }
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      updateWalkthrough().then(() => {
        props.setOpen(false);
      });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const setOpen = () => {
    //DO NOTHING
    //props.setOpen;
  };
  const cancelButtonRef = useRef(null);

  return (
    <>
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform h-4/6 overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    {!steps[activeStep].action && (
                      <div className="mx-auto flex h-64 w-96 items-center justify-center rounded-full">
                        <img src={steps[activeStep].image} alt="" />
                      </div>
                    )}

                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        {steps[activeStep].title}
                      </Dialog.Title>
                      <div className="my-4 h-12">
                        <p className="text-sm text-gray-500">
                          {steps[activeStep].body}
                        </p>
                      </div>
                      {steps[activeStep].action && (
                        <div className="mx-auto flex flex-wrap h-64 w-96 items-center content-start rounded-full">
                          {steps[activeStep].items.map((item, index) => (
                            <span
                              key={index}
                              onClick={(e) => toggleItemSelection(item)}
                              className={`${
                                selected.indexOf(item) > -1
                                  ? "bg-purple-100 font-medium border border-purple-600 text-purple-700"
                                  : "bg-gray-50 text-black-600 border"
                              } cursor-pointer items-center rounded-full px-6 py-2 mx-2 my-2 text-sm`}
                            >
                              {item}
                            </span>
                          ))}
                        </div>
                      )}
                      <div className="my-4">
                        <Box sx={{ width: "100%" }}>
                          <Stepper
                            activeStep={activeStep}
                            alternativeLabel
                            connector={<QontoConnector />}
                          >
                            {steps.map((label, index) => {
                              return (
                                <Step key={index}>
                                  <StepLabel
                                    StepIconComponent={QontoStepIcon}
                                  ></StepLabel>
                                </Step>
                              );
                            })}
                          </Stepper>
                        </Box>
                      </div>

                      <hr />
                      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                          onClick={handleNext}
                        >
                          {activeStep === steps.length - 1 ? "Finish" : "Next"}
                        </button>
                        <button
                          type="button"
                          className={
                            (activeStep === 0 ? "display-none" : "") +
                            " mt-3 inline-flex w-full justify-center rounded-md bg-white hover:bg-gray-50 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                          }
                          ref={cancelButtonRef}
                          disabled={activeStep === 0}
                          onClick={handleBack}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
