export default function PrivacyStatement() {
    return (
      <div className="bg-white px-6 py-24 sm:py-32 lg:px-8">
   <div className="mx-auto max-w-2xl">
      <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl text-center">Privacy Policy</h2>
      <p className="mt-6 text-lg leading-8 text-gray-600">
         This Privacy Policy outlines how we collect, use, and protect the personal information of individuals who interact with our services. We value your privacy and are committed to protecting your personal information in accordance with applicable data protection laws and regulations.
      </p>
      <h3 className="mt-8 text-2xl font-semibold text-gray-900">Collection of Personal Information</h3>
      <p className="mt-4 text-lg leading-8 text-gray-600">
         We may collect personal information from you when you interact with our services. The types of personal information we may collect include, but are not limited to:
      </p>
      <ul className="list-disc ml-8 mt-4 text-lg leading-8 text-gray-600">
         <li>Name</li>
         <li>Contact information (such as email address, phone number, mailing address)</li>
         <li>Demographic information</li>
         <li>Information provided voluntarily (such as through surveys or forms)</li>
      </ul>
      <h3 className="mt-8 text-2xl font-semibold text-gray-900">Use of Personal Information</h3>
      <p className="mt-4 text-lg leading-8 text-gray-600">
         We use the collected personal information for the following purposes:
      </p>
      <ul className="list-disc ml-8 mt-4 text-lg leading-8 text-gray-600">
         <li>To provide and improve our services</li>
         <li>To communicate with you and respond to your inquiries</li>
         <li>To personalize and enhance your experience</li>
         <li>To send you promotional and marketing communications (with your consent, where required by law)</li>
         <li>To comply with legal obligations</li>
      </ul>
      <h3 className="mt-8 text-2xl font-semibold text-gray-900">Sharing of Personal Information</h3>
      <p className="mt-4 text-lg leading-8 text-gray-600">
         We may share your personal information with third parties in the following circumstances:
      </p>
      <ul className="list-disc ml-8 mt-4 text-lg leading-8 text-gray-600">
         <li>With service providers and business partners who assist us in delivering our services and operating our business.</li>
         <li>With government authorities or law enforcement officials if required by applicable law or in response to a legal process.</li>
         <li>With your consent or at your direction.</li>
      </ul>
      <h3 className="mt-8 text-2xl font-semibold text-gray-900">Data Security</h3>
      <p className="mt-4 text-lg leading-8 text-gray-600">
         We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no data transmission over the internet or electronic storage system can be guaranteed to be 100% secure. Therefore, while we strive to protect your personal information, we cannot guarantee its absolute security.
      </p>
      <h3 className="mt-8 text-2xl font-semibold text-gray-900">Your Rights</h3>
      <p className="mt-4 text-lg leading-8 text-gray-600">
      You have the right to access, correct, update, or delete your personal information in our records. If you would like to exercise any of these rights or have any questions or concerns regarding the processing of your personal information, please contact us using the information provided below.
      </p>
      <h3 className="mt-8 text-2xl font-semibold text-gray-900">Data Retention</h3>
      <p className="mt-4 text-lg leading-8 text-gray-600">
         We retain personal information for as long as necessary to fulfill the purposes for which it was collected, unless a longer retention period is required or permitted by law.
      </p>
      <h3 className="mt-8 text-2xl font-semibold text-gray-900">Third-Party Websites</h3>
      <p className="mt-4 text-lg leading-8 text-gray-600">
         Our services may contain links to third-party websites or services. We are not responsible for the privacy practices or content of such third-party sites. We encourage you to read the privacy policies of those third parties before providing any personal information or using their services.
      </p>
      <h3 className="mt-8 text-2xl font-semibold text-gray-900">Updates to the Privacy Policy</h3>
      <p className="mt-4 text-lg leading-8 text-gray-600">
         We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. The updated version will be posted on our website, and the date of the latest revision will be indicated at the top of the policy.
      </p>
      <h3 className="mt-8 text-2xl font-semibold text-gray-900">Contact Us</h3>
      <p className="mt-4 text-lg leading-8 text-gray-600">
         If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us at help@sayso-project.com.
      </p>
   </div>
</div>

    )
  }
  