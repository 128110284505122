import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "components/layout/Layout";
import Home from "pages/PublicHomeHeroPage";
import NoPage from "pages/404Page";
import Login from "pages/SignInPage";
import UserBilling from "pages/SubscriptionPage";
import Subscription from "pages/PublicPricingPage";
import UserLayout from "components/layout/UserLayout";
import UserHome from "pages/ResponsePage";
import UserHelp from "pages/ContactUsPage";
import UserLibrary from "pages/LibraryPage";
import UserProfile from "pages/UserProfilePage";
import SignUp from "pages/SignUpPage";
import Privacy from "pages/PrivacyPage";
import EULA from "pages/EULAPage";
import TOS from "pages/TOSPage";
import ForgotPassword from "pages/ForgotPasswordPage";
import UserVerifyEmail from "pages/VerifyEmailPage";

import { AuthContextProvider } from "./context/AuthContext";
import ProtectedRoute from "components/common/ProtectedRoute";
import UserNavigation from "components/common/UserNavigation";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <AuthContextProvider>
      <Routes>
        <Route
          path="/u"
          element={
            <ProtectedRoute>
              <UserLayout />
            </ProtectedRoute>
          }
        >
          <Route path="/u/home" element={<UserHome />} />
          <Route path="/u/help" element={<UserHelp />} />
          <Route path="/u/library" element={<UserLibrary />} />
          <Route path="/u/billing" element={<UserBilling />} />
          <Route path="/u/profile" element={<UserProfile />} />
        </Route>

        <Route
          path="/u/verify-email"
          element={
            <ProtectedRoute>
              <UserVerifyEmail />
            </ProtectedRoute>
          }
        />

        <Route path="/" element={<Layout />}>
          <Route path="/tos" element={<TOS />} />
          <Route path="/eula" element={<EULA />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route index element={<Home />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="*" element={<NoPage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
      </Routes>
    </AuthContextProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
