import { Fragment, useState, useEffect } from "react";
import { HeartIcon } from "@heroicons/react/20/solid";
import { BellIcon } from "@heroicons/react/24/outline";
import { UserAuth } from "../../context/AuthContext";
import { clearNotificationsForUser } from "../../services/api";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Panel() {
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [newCount, setNewCount] = useState(0);

  const { user } = UserAuth();

  const setNotificationCount = async (notifications) => {
    var n = 0;
    for (var i = 0; i < notifications.length; i++) {
      if (notifications[i].status == "new") {
        n++;
      }
    }

    setNewCount(n);
  };

  const retrieveNotifications = async () => {
    const notifications = []; // await getNotificationsForUser(user);
    await setNotificationCount(notifications);
    setNotifications(notifications);
  };

  useEffect(() => {
    if (user) {
      retrieveNotifications();
    }
  }, [user]);

  const clickNotification = async () => {
    setOpen(true);
    await clearNotificationsForUser(user, notifications);
  };

  return (
    <>
      <button
        onClick={clickNotification}
        type="button"
        className="relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        <span className="absolute -inset-1.5" />
        <span className="sr-only">View notifications</span>
        {newCount > 0 && (
          <span className="absolute inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white transform translate-x-1/8 -translate-y-1/4 bg-red-600 rounded-full">
            {newCount}
          </span>
        )}

        <BellIcon className="h-6 w-6" aria-hidden="true" />
      </button>
      <Menu as="div" className="relative inline-block text-left">
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpen}>
            <div className="fixed inset-0" />

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                      <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                        <div className="p-6">
                          <div className="flex items-start justify-between">
                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                              Notifications
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                                onClick={() => setOpen(false)}
                              >
                                <span className="absolute -inset-2.5" />
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="border-b border-gray-200"></div>
                        <ul className="flex-1 divide-y divide-gray-200 overflow-y-auto">
                          {notifications.length == 0 && (
                            <span className="px-6 py-6 text-xs">
                              No notifications
                            </span>
                          )}
                          {notifications.map((notification) => (
                            <li key={notification.id}>
                              <div className="group relative flex items-center px-5 py-6">
                                <span className="-m-1 block cursor-pointer flex-1 p-1">
                                  <div
                                    className={classNames(
                                      notification.status == "new"
                                        ? "bg-indigo-50"
                                        : "",
                                      "absolute inset-0 group-hover:bg-indigo-100"
                                    )}
                                    aria-hidden="true"
                                  />
                                  <div className="relative flex min-w-0 flex-1 items-center">
                                    <span className="relative inline-block flex-shrink-0">
                                      <HeartIcon
                                        className="h-7 w-7 text-gray-500"
                                        alt=""
                                      />
                                      <span
                                        className={classNames(
                                          notification.status == "new"
                                            ? "bg-blue-400 absolute top-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white"
                                            : ""
                                        )}
                                        aria-hidden="true"
                                      />
                                    </span>
                                    <div className="ml-4">
                                      <p className="truncate text-sm font-medium text-gray-900">
                                        {notification.subject}
                                      </p>
                                      <p className="whitespace-normal break-all text-xs text-gray-500">
                                        {notification.message}
                                      </p>
                                    </div>
                                  </div>
                                </span>
                                <span
                                  as="div"
                                  className="relative ml-2 inline-block flex-shrink-0 inline-flex h-4 w-4"
                                />
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </Menu>
    </>
  );
}
