import { Outlet } from "react-router-dom";

import UserNavigation from "../common/UserNavigation";
import Banner from "../common/Banner";

export default function Layout() {
  return (
    <>
      <div className="bg-slate-50">
        <UserNavigation />
        <Banner />
        <div className="mx-auto max-w-7xl lg:px-4 sm:px-6 lg:px-8 min-h-screen">
          {/* We've used 3xl here, but feel free to try other max-widths based on your needs */}
          <div className="mx-auto max-w-7xl ">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
