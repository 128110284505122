"use client";

import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

import { UserAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import NotificationsPanel from "./NotificationsPanel";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navigation() {
  const { user, logout } = UserAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
      console.log("You are logged out");
    } catch (error) {
      console.log(error);
    }
  };

  const getItemClassNames = (route) => {
    if (window.location.pathname === route) {
      return "inline-flex items-center border-b-2 border-indigo-500 px-1 pt-1 text-sm font-medium text-gray-900";
    }
    return "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700";
  };

  const getMobileItemClassNames = (route) => {
    if (window.location.pathname === route) {
      return "block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700";
    }
    return "block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700";
  };

  return (
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="h-8 w-auto"
                    src="/sayso-logo.png"
                    alt="SaySo"
                  />
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                  {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                  <a href="/u/home" className={getItemClassNames("/u/home")}>
                    Home
                  </a>
                  <a
                    href="/u/library"
                    className={getItemClassNames("/u/library")}
                  >
                    Journal
                  </a>
                  <a href="/u/help" className={getItemClassNames("/u/help")}>
                    Help
                  </a>
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                <NotificationsPanel />

                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open user menu</span>
                      {user?.photoURL && (
                        <img
                          className="h-8 w-8 rounded-full"
                          src={user?.photoURL}
                          alt=""
                        />
                      )}
                      {!user?.photoURL && (
                        <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
                          <span className="text-sm font-medium leading-none text-white">
                            U
                          </span>
                        </span>
                      )}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="/u/profile"
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Your Profile
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="/u/billing"
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Subscription
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item onClick={handleLogout}>
                        {({ active }) => (
                          <span
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700 border-t-2"
                            )}
                          >
                            Sign out
                          </span>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 pb-3 pt-2">
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}

              <Disclosure.Button
                as="a"
                href="/u/home"
                className={getMobileItemClassNames("/u/home")}
              >
                Home
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="/u/library"
                className={getMobileItemClassNames("/u/library")}
              >
                Journal
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="/u/help"
                className={getMobileItemClassNames("/u/help")}
              >
                Help
              </Disclosure.Button>
            </div>
            <div className="border-t border-gray-200 pb-3 pt-4">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                  <span className="sr-only">Open user menu</span>
                  {user?.photoURL && (
                    <img
                      className="h-8 w-8 rounded-full"
                      src={user?.photoURL}
                      alt=""
                    />
                  )}
                  {!user?.photoURL && (
                    <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
                      <span className="text-sm font-medium leading-none text-white">
                        U
                      </span>
                    </span>
                  )}
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">
                    {user.displayName ? user.displayName : "SaySo User"}
                  </div>
                  <div className="text-sm font-medium text-gray-500">
                    {user.email}
                  </div>
                </div>
              </div>
              <div className="mt-3 space-y-1">
                <Disclosure.Button
                  as="a"
                  href="/u/profile"
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                >
                  Your Profile
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="/u/billing"
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                >
                  Subscription
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="#"
                  onClick={handleLogout}
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                >
                  Sign out
                </Disclosure.Button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
    // <Disclosure as="nav" className="bg-white shadow">
    //   {({ open }) => (
    //     <>
    //       <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
    //         <div className="relative flex h-16 justify-between">
    //           <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
    //             {/* Mobile menu button */}
    //             <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
    //               <span className="sr-only">Open main menu</span>
    //               {open ? (
    //                 <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
    //               ) : (
    //                 <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
    //               )}
    //             </Disclosure.Button>
    //           </div>
    //           <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
    //             <div className="flex flex-shrink-0 items-center">
    //               <img
    //                 className="block h-8 w-auto lg:hidden"
    //                 src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
    //                 alt="Your Company"
    //               />
    //               <img
    //                 className="hidden h-8 w-auto lg:block"
    //                 src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
    //                 alt="Your Company"
    //               />
    //             </div>
    //             <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
    //               {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
    //               <a
    //                 href="/u/home"
    //                 className={getItemClassNames('/u/home')}
    //               >
    //                 Home
    //               </a>
    //               <a
    //                 href="/u/library"
    //                 className={getItemClassNames('/u/library')}
    //               >
    //                 Library
    //               </a>
    //               <a
    //                 href="/u/help"
    //                 className={getItemClassNames('/u/help')}
    //               >
    //                 Help
    //               </a>
    //             </div>
    //           </div>
    //           <div className="hidden sm:ml-6 sm:flex sm:space-x-8 flex items-center">
    //           <div className="flex-shrink-0">
    //               <button
    //                 type="button"
    //                 className="relative inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    //                 onClick={(e) => {navigate('/u/home')}}
    //               >
    //                 <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
    //                 New Entry
    //               </button>
    //             </div>
    //           </div>
    //           <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
    //             <NotificationsPanel/>

    //             {/* Profile dropdown */}
    //             <Menu as="div" className="relative ml-3">
    //               <div>
    //                 <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
    //                   <span className="sr-only">Open user menu</span>
    //                   {user?.photoURL && <img
    //                     className="h-8 w-8 rounded-full"
    //                     src={user?.photoURL}
    //                     alt=""
    //                   />
    //                   }
    //                   {
    //                     !user?.photoURL &&
    //                     <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
    //                     <span className="text-sm font-medium leading-none text-white">U</span>
    //                     </span>

    //                   }

    //                 </Menu.Button>
    //               </div>
    //               <Transition
    //                 as={Fragment}
    //                 enter="transition ease-out duration-200"
    //                 enterFrom="transform opacity-0 scale-95"
    //                 enterTo="transform opacity-100 scale-100"
    //                 leave="transition ease-in duration-75"
    //                 leaveFrom="transform opacity-100 scale-100"
    //                 leaveTo="transform opacity-0 scale-95"
    //               >
    //                 <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
    //                   <Menu.Item>
    //                     {({ active }) => (
    //                       <a
    //                         href="/u/profile"
    //                         className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
    //                       >
    //                         Your Profile
    //                       </a>
    //                     )}
    //                   </Menu.Item>
    //                   <Menu.Item>
    //                     {({ active }) => (
    //                       <a
    //                         href="/u/billing"
    //                         className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
    //                       >
    //                         Subscription
    //                       </a>
    //                     )}
    //                   </Menu.Item>
    //                   <Menu.Item onClick={handleLogout}>
    //                     {({ active }) => (
    //                       <span
    //                         className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 border-t-2')}
    //                       >
    //                         Sign out
    //                       </span>
    //                     )}
    //                   </Menu.Item>
    //                 </Menu.Items>
    //               </Transition>
    //             </Menu>
    //           </div>
    //         </div>
    //       </div>

    //       <Disclosure.Panel className="sm:hidden">
    //         <div className="space-y-1 pb-4 pt-2">
    //           {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
    //           <Disclosure.Button
    //             as="a"
    //             href="/u/home"
    //             className={getMobileItemClassNames("/u/home")}
    //           >
    //             Home
    //           </Disclosure.Button>
    //           <Disclosure.Button
    //             as="a"
    //             href="/u/library"
    //             className={getMobileItemClassNames("/u/library")}
    //           >
    //             Library
    //           </Disclosure.Button>
    //           <Disclosure.Button
    //             as="a"
    //             href="/u/help"
    //             className={getMobileItemClassNames("/u/help")}
    //           >
    //             Help
    //           </Disclosure.Button>
    //         </div>
    //       </Disclosure.Panel>
    //     </>
    //   )}
    // </Disclosure>
  );
}
