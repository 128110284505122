import { useState } from "react";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { UserAuth } from "../context/AuthContext";
import Loading from "../components/common/Loading";
import { setHelp } from "../services/api";
import { CheckIcon } from "@heroicons/react/20/solid";
import * as Constants from "util/constants";

export default function ContactUsView() {
  const [error, setError] = useState(null);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  //Notification
  const [show, setShow] = useState(false);
  const [viewable, setViewable] = useState({});

  const { user } = UserAuth();

  const createDocument = async (e) => {
    e.preventDefault();
    // setError(null);
    setLoading(true);

    if (subject === "") {
      alert("Subject is required");
      setLoading(false);
      return;
    }

    const doc = {
      uid: user.uid,
      subject: subject,
      message: message,
      status: "new",
    };

    const resp = await setHelp(user, doc);
    if (resp.success) {
      setSubject("");
      setMessage("");
      setShow(true);
      setViewable({
        type: "success",
        title: "Message received!",
        message:
          "The SaySo team have received your request, we will get back to you as soon as possible.",
      });
    } else {
      console.log("Something went wrong");
    }

    setLoading(false);
  };

  return (
    <div className="">
      <div className="mx-auto max-w-7xl px-2 lg:px-6 py-4 lg:py-16 sm:py-24 lg:px-8">
        <div className="relative shadow-2xl bg-white animate-fade-up">
          <h2 className="sr-only">Contact us</h2>

          <div className="grid grid-cols-1 lg:grid-cols-3">
            {/* Contact information */}
            <div className="relative overflow-hidden bg-indigo-700 px-6 py-10 sm:px-10 xl:p-12">
              <div
                className="pointer-events-none absolute inset-0 sm:hidden"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 h-full w-full"
                  width={343}
                  height={388}
                  viewBox="0 0 343 388"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                >
                  <path
                    d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                    fill="url(#linear1)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear1"
                      x1="254.553"
                      y1="107.554"
                      x2="961.66"
                      y2="814.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                className="pointer-events-none absolute bottom-0 border-gray-500 right-0 top-0 hidden w-1/2 sm:block lg:hidden"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 h-full w-full"
                  width={359}
                  height={339}
                  viewBox="0 0 359 339"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                >
                  <path
                    d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                    fill="url(#linear2)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear2"
                      x1="192.553"
                      y1="28.553"
                      x2="899.66"
                      y2="735.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                className="pointer-events-none absolute bottom-0 right-0 top-0 hidden w-1/2 lg:block"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 h-full w-full"
                  width={160}
                  height={678}
                  viewBox="0 0 160 678"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                >
                  <path
                    d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                    fill="url(#linear3)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear3"
                      x1="192.553"
                      y1="325.553"
                      x2="899.66"
                      y2="1032.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <h3 className="text-lg font-medium text-white">
                Contact information
              </h3>
              <p className="mt-6 max-w-3xl text-base text-indigo-50">
                Let us know if you have any feedback, or get in touch if you
                have any issues using SaySo.
              </p>
              <dl className="mt-8 space-y-6">
                <dt>
                  <span className="sr-only">Phone number</span>
                </dt>
                <dt>
                  <span className="sr-only">Email</span>
                </dt>
                <dd className="flex text-base text-indigo-50">
                  <EnvelopeIcon
                    className="h-6 w-6 flex-shrink-0 text-indigo-200"
                    aria-hidden="true"
                  />
                  <span className="ml-3">{Constants.SAYSO_EMAIL}</span>
                </dd>
              </dl>
            </div>

            {/* Contact form */}
            {show && (
              <div className="flex flex-col items-center justify-center mt-8 mb-16 px-6 py-10 sm:px-10 lg:col-span-2 xl:p-12 animate-fade-down animate-ease-out">
                <span className="bg-green-500 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white">
                  <CheckIcon
                    className="h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                </span>
                <span className="mt-4 px-4 font-semibold">
                  Your message has been sent!
                </span>
                <span className="mt-4 px-4">
                  The team will get back to you as soon as possible.
                </span>
              </div>
            )}
            {!show && (
              <div className="px-6 py-10 sm:px-10 lg:col-span-2 xl:p-12">
                <h3 className="text-lg font-medium text-gray-900">
                  Send us a message
                </h3>
                <form
                  onSubmit={(e) => createDocument(e)}
                  className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
                >
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="subject"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Subject
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="subject"
                        id="subject"
                        className="block w-full rounded-md border-gray-300 px-4 py-3 text-gray-900 ring-1 ring-inset ring-gray-300 shadow-md focus:border-indigo-500 focus:ring-indigo-500"
                        placeholder="Enter your subject"
                        onChange={(e) => setSubject(e.target.value)}
                        value={subject}
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <div className="flex justify-between">
                      <label
                        htmlFor="message"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Message
                      </label>
                      <span id="message-max" className="text-sm text-gray-500">
                        Max. 500 characters
                      </span>
                    </div>
                    <div className="mt-1">
                      <textarea
                        id="message"
                        name="message"
                        rows={4}
                        className="block w-full rounded-md border-gray-300 px-4 py-3 text-gray-900 shadow-md ring-1 ring-inset ring-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                        aria-describedby="message-max"
                        defaultValue={""}
                        placeholder="Enter your message"
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2 sm:flex sm:justify-end">
                    {!loading && (
                      <button
                        type="submit"
                        className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                        disabled={loading}
                      >
                        Submit
                      </button>
                    )}
                    {loading && (
                      <div className="mt-2 inline-flex w-full items-center justify-center px-6 py-3 sm:w-auto">
                        <Loading size="h-8 w-8" />
                      </div>
                    )}
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
