export default function BillingPlan(props) {
  const redirect = props.redirect;

  const subscription = props.subscription;
  const hasSub = subscription.active;

  return (
    <>
      <div className="animate-fade-up shadow-lg bg-white flex flex-col items-start gap-x-8 gap-y-6 rounded-3xl p-8 ring-1 ring-gray-900/10 sm:gap-y-10 sm:p-10 lg:col-span-2 lg:flex-row lg:items-center">
        <div className="lg:min-w-0 lg:flex-1">
          <h3 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">
            Your plan
          </h3>
          <p className="mt-1 text-base leading-7 text-gray-600">
            You are currently on the <b>{subscription.name}</b>.{" "}
            {subscription.description}
          </p>
        </div>

        <span
          className="relative inline-flex cursor-pointer items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={redirect}
        >
          Manage my subscription <span aria-hidden="true">&rarr;</span>
        </span>
      </div>
    </>
  );
}
