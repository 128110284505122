export default function TermsOfService() {
    return (
      <div className="bg-white px-6 py-24 sm:py-32 lg:px-8">
   <div className="mx-auto max-w-2xl">
      <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl text-center">Terms of Service</h2>
      <p className="mt-6 text-lg leading-8 text-gray-600">
         These Terms of Service ("Agreement") govern your use of SaySo Project ("App") provided by Craft IT ("Company"). By accessing or using the App, you agree to be bound by the terms and conditions of this Agreement.
      </p>
      <h3 className="mt-8 text-2xl font-semibold text-gray-900">1. Use of the App</h3>
      <p className="mt-4 text-lg leading-8 text-gray-600">
         The App is provided for your personal, non-commercial use only. You may not use the App for any illegal or unauthorized purpose, and you agree to comply with all applicable laws and regulations while using the App.
      </p>
      <h3 className="mt-8 text-2xl font-semibold text-gray-900">2. Intellectual Property</h3>
      <p className="mt-4 text-lg leading-8 text-gray-600">
         All intellectual property rights in the App, including but not limited to copyrights, trademarks, and trade secrets, are owned by Company. You agree not to reproduce, modify, or distribute the App or its contents without Company's prior written consent.
      </p>
      <h3 className="mt-8 text-2xl font-semibold text-gray-900">3. User Content</h3>
      <p className="mt-4 text-lg leading-8 text-gray-600">
         By using the App, you may submit or upload content ("User Content"). You retain ownership of your User Content, but you grant Company a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, adapt, publish, and distribute your User Content for the purposes of operating and improving the App.
      </p>
      <h3 className="mt-8 text-2xl font-semibold text-gray-900">4. Prohibited Conduct</h3>
      <p className="mt-4 text-lg leading-8 text-gray-600">
         You agree not to engage in any of the following prohibited conduct:
      </p>
      <ul className="list-disc ml-8 mt-4 text-lg leading-8 text-gray-600">
         <li>Violating any applicable laws or regulations.</li>
         <li>Interfering with or disrupting the operation of the App.</li>
         <li>Impersonating any person or entity.</li>
         <li>Harassing, intimidating, or harming others.</li>
         <li>Uploading or transmitting viruses or other malicious code.</li>
         <li>Engaging in any activity that may compromise the security or integrity of the App.</li>
      </ul>
      <h3 className="mt-8 text-2xl font-semibold text-gray-900">5. Limitation of Liability</h3>
      <p className="mt-4 text-lg leading-8 text-gray-600">
         In no event shall Company be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of the App. You agree to use the App at your own risk.
      </p>
      <h3 className="mt-8 text-2xl font-semibold text-gray-900">6. Modifications to the App</h3>
      <p className="mt-4 text-lg leading-8 text-gray-600">
        Company reserves the right to modify, suspend, or discontinue the App or any part thereof at any time without prior notice. You agree that Company shall not be liable to you or any third party for any modification, suspension, or discontinuation of the App.
      </p>
      <h3 className="mt-8 text-2xl font-semibold text-gray-900">7. Privacy</h3>
      <p className="mt-4 text-lg leading-8 text-gray-600">
        Your use of the App is subject to the Company's Privacy Policy. By using the App, you consent to the collection, use, and disclosure of your information as described in the Privacy Policy.
      </p>
      <h3 className="mt-8 text-2xl font-semibold text-gray-900">8. Termination</h3>
      <p className="mt-4 text-lg leading-8 text-gray-600">
        This Agreement is effective until terminated. Company may terminate this Agreement at any time without notice if you fail to comply with any term of this Agreement. Upon termination, you shall cease all use of the App and destroy any copies in your possession.
      </p>
      <h3 className="mt-8 text-2xl font-semibold text-gray-900">9. Governing Law</h3>
      <p className="mt-4 text-lg leading-8 text-gray-600">
        This Agreement shall be governed by and construed in accordance with the laws of [Jurisdiction]. Any disputes arising under or in connection with this Agreement shall be subject to the exclusive jurisdiction of the courts located in [Jurisdiction].
      </p>
      <h3 className="mt-8 text-2xl font-semibold text-gray-900">10. Severability</h3>
      <p className="mt-4 text-lg leading-8 text-gray-600">
        If any provision of this Agreement is found to be invalid or unenforceable, the remaining provisions shall continue to be valid and enforceable to the fullest extent permitted by law.
      </p>
      <h3 className="mt-8 text-2xl font-semibold text-gray-900">11. Entire Agreement</h3>
      <p className="mt-4 text-lg leading-8 text-gray-600">
        This Agreement constitutes the entire agreement between you and Company regarding the use of the App and supersedes all prior agreements and understandings, whether written or oral.
      </p>
      <h3 className="mt-8 text-2xl font-semibold text-gray-900">12. Contact Information</h3>
      <p className="mt-4 text-lg leading-8 text-gray-600">
        If you have any questions or concerns about this Agreement, please contact us at help@sayso-project.com.
      </p>
      </div>
      </div>
    )
  }
  