import GoogleIcon from "@mui/icons-material/Google";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { useState } from "react";
import Alert from "../components/alert/Alert";
import getErrorMessage from "../util/error-codes";
import Loading from "../components/common/Loading";
import ReCAPTCHADisclosure from "../components/common/ReCAPTCHADisclosure";

const emailSuccessAlert = {
  type: "success",
  title: "Please check your emails to complete setup!",
  // messages: ['Please check your emails for a verification link to complete account setup!']
};

const passMismatchFailAlert = {
  type: "fail",
  title: "Your passwords must match",
};

const emailFailAlert = {
  type: "fail",
  title:
    "An unexpected error occurred when sending your verification email. Please contact us at help@sayso-project.com",
};

const unexpectedErrorAlert = (message) => {
  const alert = {
    type: "fail",
    title: [message],
  };
  return alert;
};

export default function SignUpView() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [alert, setAlert] = useState({});
  const [loading, setLoading] = useState(false);
  const { createUser, sendUserEmailVerification, user } = UserAuth();

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAlert({});
    setLoading(true);

    if (password !== confirmPassword) {
      setAlert(passMismatchFailAlert);
      setLoading(false);
      return;
    }

    await createUser(email, password)
      .then((result) => {
        sendUserEmailVerification().then((response) => {
          navigate("/u/home");
        });
      })
      .catch((error) => {
        setAlert(unexpectedErrorAlert(getErrorMessage(error)));
        setLoading(false);
      });
  };

  return (
    <>
      {/*
          This example requires updating your template:
  
          ```
          <html class="h-full bg-gray-50">
          <body class="h-full">
          ```
        */}
      <div className="bg-slate-50 flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-10 w-auto"
            src="sayso-logo.png"
            alt="SaySo"
          />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign up for an account
          </h2>
        </div>

        <div className="lg:mt-10 mt-5 mx-2 sm:mx-auto sm:w-full sm:max-w-[480px]">
          {alert.type && <Alert alert={alert} />}
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            {loading && (
              <div className="flex flex-col items-center justify-center">
                <Loading />
              </div>
            )}
            {!loading && (
              <form className="space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Password
                  </label>
                  <div className="mt-2">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="confirmPassword"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Confirm Password
                  </label>
                  <div className="mt-2">
                    <input
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Create account
                  </button>
                </div>
              </form>
            )}
          </div>

          <p className="mt-10 text-center text-sm text-gray-500">
            Already a member?{" "}
            <a
              href="/login"
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Sign in to your account
            </a>
          </p>
          <ReCAPTCHADisclosure />
        </div>
      </div>
    </>
  );
}
